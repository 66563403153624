@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Upheaval";
  src: url("./fonts/upheaval.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Consolas";
  src: url("./fonts/consola.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Upheaval", sans-serif;
}
