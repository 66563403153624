.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px) rotateZ(0deg);
  }
  50% {
    transform: translate(0, 15px) rotateZ(22deg);
  }
  100% {
    transform: translate(0, -0px) rotateZ(0deg);
  }
}

.floating2 {
  animation-name: floating2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating2 {
  0% {
    transform: translate(0, 0px) rotateZ(0deg);
  }
  50% {
    transform: translate(0, 15px) rotateZ(-22deg);
  }
  100% {
    transform: translate(0, -0px) rotateZ(0deg);
  }
}

.floatingCloud {
  animation-name: floatingCloud;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingCloud {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/* CAROUSEL */

@media (min-width: 768px) {
  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-100px * 7));
      transform: translateX(calc(-100px * 7));
    }
  }
  @keyframes scrollReverse {
    0% {
      -webkit-transform: translateX(calc(-100px * 7));
      transform: translateX(calc(-100px * 7));
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .slider {
    background: pr;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .slider::before,
  .slider::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(100px * 50);
  }
  .slider .slide {
    height: 100%;
    width: 100px;
  }

  /* REVERSE */
  .sliderReverse {
    background: pr;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .sliderReverse::before,
  .sliderReverse::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .sliderReverse::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  .sliderReverse::before {
    left: 0;
    top: 0;
  }
  .sliderReverse .slideReverse-track {
    -webkit-animation: scrollReverse 40s linear infinite;
    animation: scrollReverse 40s linear infinite;
    display: flex;
    width: calc(100px * 50);
  }
  .sliderReverse .slideReverse {
    height: 100%;
    width: 100px;
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-80px * 7));
    transform: translateX(calc(-80px * 7));
  }
}
@keyframes scrollReverse {
  0% {
    -webkit-transform: translateX(calc(-80px * 7));
    transform: translateX(calc(-80px * 7));
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slider {
  background: pr;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(80px * 50);
}
.slider .slide {
  height: 100%;
  width: 80px;
}

/* REVERSE */
.sliderReverse {
  background: pr;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.sliderReverse::before,
.sliderReverse::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.sliderReverse::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.sliderReverse::before {
  left: 0;
  top: 0;
}
.sliderReverse .slideReverse-track {
  -webkit-animation: scrollReverse 40s linear infinite;
  animation: scrollReverse 40s linear infinite;
  display: flex;
  width: calc(80px * 50);
}
.sliderReverse .slideReverse {
  height: 100%;
  width: 80px;
}

@keyframes buttonKeyframes {
  to {
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
  }
}

.buttonHero {
  box-shadow: 5px 5px 0px #382261;
  transition: 0.3s ease-in-out 0.1s;
  min-width: 15%;
}
.buttonHero:hover {
  transition: 0.3s ease-in-out;
  box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
  min-width: 55%;
}

@media (min-width: 640px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 15%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 20%;
  }
}

@media (min-width: 768px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 15%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 20%;
  }
}

@media (min-width: 1024px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 12%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 30%;
  }
}

@media (min-width: 1280px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 12%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 30%;
  }
}

@media (min-width: 1536px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 12%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 30%;
  }
}

@media (min-width: 1900px) {
  .buttonHero {
    box-shadow: 5px 5px 0px #382261;
    transition: 0.3s ease-in-out 0.1s;
    min-width: 12%;
  }
  .buttonHero:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 5px 0px #a4ee16, 10px 10px 0px #382261;
    min-width: 20%;
  }
}
